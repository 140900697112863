<template>
  <div class="tbl-wrap">
    <table class="tbl">
      <thead>
      <tr>
        <th class="tbl__col tbl__col--xs"></th>
        <th class="tbl__col tbl__col--main">Задача</th>
        <th>Дата и время</th>
        <th v-if="!project_id">Проект</th>
        <th>Исполнитель</th>
        <th class="tbl__col tbl__col--xs">ID</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="request in requests" class="pu-link task-line" :class="request.classes" @click="showModal(request.id)">
        <td>
        <span class="task-icon" :class="request.status.className">
          <svg class="svg" width="16" height="16">
            <use :href="'/img/sprite-' + appVersion +'.svg#' + request.status.icon + '-16'"/>
          </svg>
        </span>
        </td>
        <td>
          <div class="col-main task-name">{{request.name}}
            <div v-if="request.unread_comments_amount > 0" class="task-name__qty"><span class="notify-qty">{{request.unread_comments_amount}}</span></div>
          </div>
        </td>
        <td><span v-if="request.start_date && request.start_time" class="col-extra">{{getTimeZoneDateTime('date', request)}} {{getTimeZoneDateTime('time', request)}}</span>
          <span v-else-if="request.start_date" class="col-extra">{{request.start_date}}</span>
        </td>
        <td v-if="!project_id"><span class="col-extra">{{request.project?.name}}</span></td>
        <td>
          <div class="members">
            <div v-for="executor in request.executors" class="members__item">
              <div class="member">
                <img :src="executor.photo" width="24" height="24" alt="">
              </div>
            </div>
          </div>
        </td>
        <td><span class="col-extra col-id">{{request.id}}</span></td>
      </tr>

      <template v-if="archiveRequests.length">
        <tr>
          <td colspan="6">
            <div class="col-title col-title--gray">Архив</div>
          </td>
        </tr>

        <tr v-for="request in archiveRequests" @click="showModal(request.id)" class="pu-link task-line closed">
          <td>&nbsp;</td>
          <td>
            <div class="col-main task-name">{{request.name}}</div>
          </td>
          <td><span v-if="request.start_date && request.start_time" class="col-extra">{{getTimeZoneDateTime('date', request)}} {{getTimeZoneDateTime('time', request)}}</span>
            <span v-else class="col-extra">{{request.start_date}}</span>
          </td>
          <td v-if="!project_id"><span class="col-extra">{{request.project?.name}}</span></td>
          <td>
            <div class="members">
              <div v-for="executor in request.executors" class="members__item">
                <div class="member">
                  <img :src="executor.photo" width="24" height="24" alt="">
                </div>
              </div>
            </div>
          </td>
          <td><span class="col-extra col-id">{{request.id}}</span></td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';

export default {
  props: {
    project_id: String,
    requests: Array,
    archiveRequests: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
    }
  },
  methods: {
    getTimeZoneDateTime(type, request) {
      let date = new Date(request.start_date + ' ' + request.start_time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        date.setHours(date.getHours() + Math.abs(timezone));
      } else if (timezone > 0) {
        date.setHours(date.getHours() - Math.abs(timezone));
      }
      if (type === 'date') {
        return ('0' + date.getDate()).slice(-2) + '.' + ('0'+(date.getMonth()+1)).slice(-2) + '.' + date.getFullYear();
      } else {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      }
    },
    showModal(id) {
      this.$emit('showModal', id);
    }
  }
};
</script>