<template>
  <form >
    <div class="serv-tbl__row">
      <div class="serv-tbl__col">
        <div class="form-item">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.name && touched }">
            <input type="text" v-model="form.name" class="form-control" title="Название услуги">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <div class="form-item" data-suffix="мин">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.reaction_time && touched }">
            <input type="number" v-model="form.reaction_time" min="0" step="5" class="form-control">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
        <div class="form-item" data-suffix="мин">
          <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
               :class="{ 'has-error': !form.decision_time && touched }">
            <input type="number" v-model="form.decision_time" min="0" step="5" class="form-control">
          </div>
        </div>
      </div>
      <div class="serv-tbl__col">
      </div>
      <div class="serv-tbl__col">
        <button @click="hide" class="btn btn--2 btn--m btn--primary" type="button">
          <span class="btn__label">Отмена</span>
        </button>
      </div>
      <div class="serv-tbl__col">
        <button @click="submit" class="btn btn--1 btn--m btn--primary" type="button">
          <span class="btn__label">Сохранить</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    service: Object,
  },
  data() {
    return {
      isValid: false,
      form: {
        name: null,
        reaction_time: null,
        decision_time: null,
      },
      touched: false
    }
  },
  mounted() {
    if (this.service) {
      this.service.name ? this.form.name = this.service.name : null;
      this.service.reaction_time ? this.form.reaction_time = this.service.reaction_time : null;
      this.service.decision_time ? this.form.decision_time = this.service.decision_time : null;
      this.isValid = true;
    }
  },
  methods: {
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    clearForm() {
      var props = Object.getOwnPropertyNames(this.form);
      for (var i = 0; i < props.length; i++) {
        delete this.form[props[i]];
      }
    },
    hide() {
      this.$emit('hide');
      this.clearForm();
    },
    submit() {
      this.$emit('submit', this.form);
      // this.clearForm();
    }
  }
};
</script>