<template>
  <div class="main__wrap">
    <div class="main__content content content--wide">
      <div class="content__main">
        <div class="content__body">
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name" class="form-label">Название <span v-if="dataChanged['name']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--l form-field--primary"
                       :class="{ 'has-error': !form.name && touched }">
                    <input id="name" @change="saveChanges" v-model="form.name" type="text" class="form-control" placeholder="">
                  </div>
                </div>
              </div>
              <div class="col col--fourth">
                <div class="form-group">
                  <label for="emoji" class="form-label">Эмодзи <span v-if="dataChanged['emoji']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="emoji" @change="saveChanges" v-model="form.emoji" type="text" class="form-control" placeholder="">
                  </div>
                </div>
              </div>
              <div class="col col--fourth-3">
                <div class="form-group">
                  <div class="form-label">Логотип</div><span v-if="dataChanged['logo']" class="q-note q-note--saved">Сохранено</span>
                  <div class="form-field form-field--files form-field--2 form-field--m form-field--primary">
                    <div class="file-attach">
                      <div class="file-attach__main">
                        <div v-if="!logoUrl" class="file-attach__placeholder">Прикрепите файл</div>
                        <div v-if="logoUrl" class="file-attach__placeholder"><img style="display: block; width: 100px;" :src="logoUrl"></div>
                      </div>
                      <label class="file-attach__label">
                        <input type="file" @change="uploadLogo" ref="upload-logo" class="file-attach__input">
                        <span class="file-attach__btn">Обзор</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="form-label">Отображать задачи</div><span v-if="dataChanged['types']" class="q-note q-note--saved">Сохранено</span>
                  <div class="list list--sm">
                    <div v-for="type in types" class="list__item">
                      <div class="form-group">
                        <label class="switch checkbox checkbox--s" :class="{'switch--disabled': type.name === 'list'}">
                          <input type="checkbox" @change="changeViewType(type.id)" class="switch__input" :disabled="type.name === 'list'"
                                 :checked="(type.name === 'list') || (project && project.types.includes(type.id))">
                          <span class="switch__cover checkbox__cover">
                            <span class="icon icon--s">
                              <svg class="svg" width="16" height="16">
                                <use :href="'/img/sprite-' + appVersion + '.svg#check-16'"></use>
                              </svg>
                            </span>
                          </span>
                          <span class="switch__label checkbox__label">{{type.description}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="users" class="form-label">Участники проекта <span v-if="dataChanged['users']" class="q-note q-note--saved">Сохранено</span></label>

                  <div class="form-field form-field--select form-field--2 form-field--m form-field--primary">
                    <select id="users" ref="users" v-model="form.users" @change="saveChanges" class="form-control" multiple>
                      <option data-placeholder="true">Выберите участников</option>
                      <option v-for="user in users" :value="user.id">{{user.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="tbl-wrap">
                  <table class="tbl tbl--card">
                    <thead>
                    <tr>
                      <th>Участник может</th>
                      <th>создавать задачи</th>
                      <th>редактировать задачи</th>
                      <th>удалять задачи</th>
                      <th width="48"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(permission, index) in form.usersPermissions">
                      <td>{{permission.name}}</td>
                      <td>
                        <div class="form-group">
                          <label class="switch toggle toggle--m">
                            <input v-model="form.usersPermissions[index].create_tasks" @change="saveChanges" type="checkbox"
                                   class="switch__input" :disabled="form.usersPermissions[index].delete_disabled">
                            <span class="switch__cover toggle__cover"></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="switch toggle toggle--m">
                            <input v-model="form.usersPermissions[index].edit_tasks" @change="saveChanges" type="checkbox"
                                   class="switch__input" :disabled="form.usersPermissions[index].delete_disabled">
                            <span class="switch__cover toggle__cover"></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label class="switch toggle toggle--m">
                            <input v-model="form.usersPermissions[index].delete_tasks" @change="saveChanges" type="checkbox"
                                   class="switch__input" :disabled="form.usersPermissions[index].delete_disabled">
                            <span class="switch__cover toggle__cover"></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <button v-if="!form.usersPermissions[index].delete_disabled" @click="deleteUser(form.usersPermissions[index].id)"
                                class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                          <span class="btn__icon">
                            <span class="icon icon--s">
                              <svg class="svg" width="16" height="16">
                                <use :href="'/img/sprite-' + appVersion + '.svg#delete-16'"></use>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="content__block">
            <div class="row">
              <div class="col">
                <div v-if="error" class="login-box__message login-box__message_left">
                  <span class="form-error" v-html="error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button v-if="project" @click="deleteProject" class="btn btn--2 btn--m btn--primary btn--icon" type="button">
            <span class="btn__icon">
              <span class="icon icon--m">
                <svg class="svg" width="24" height="24">
                  <use :href="'/img/sprite-' + appVersion + '.svg#delete-24'"></use>
                </svg>
              </span>
            </span>
          </button>

          <button v-if="!project" @click="addProject" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
            <span class="btn__label">Добавить</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo  from '../../../../package';
import SlimSelect from 'slim-select';

export default {
  props: {
    project: Object,
    types: Array,
    users: Array,
    usersPermissions: Array
  },
  data() {
    return {
      appVersion: packageInfo.version,
      error: null,
      isValid: false,
      form: {
        name: null,
        users: [],
        emoji: null,
        types: [1],
        usersPermissions: []
      },
      dataChanged: {},
      touched: null,
      init: true,
      logoUrl: null,
      logoToUpload: null,
      timer: null,
      usersSelect: null
    }
  },
  mounted() {
    this.usersSelect = new SlimSelect({
      select: this.$refs['users'],
      settings: {
        showSearch: true,
        searchPlaceholder: 'Поиск пользователя',
        searchText: 'Пользователи не найдены',
        searchingText: 'Идёт поиск...',
        allowDeselect: false
      }
    });
    new SlimSelect({
      select: this.$refs['types'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });
    if (this.project) {
      this.project.logo ? this.logoUrl = this.project.logo : null;
      for (var key in this.project) {
        if (this.project[key]) {
          this.form[key] = this.project[key];
        }
      }
      this.form.usersPermissions = this.usersPermissions;
    } else {
      this.form.users = [this.usersPermissions[0].id];
      this.form.usersPermissions = this.usersPermissions;
    }
    setTimeout(() => {
      this.init = false;
    }, '250');
  },
  methods: {
    uploadLogo() {
      let file = this.$refs['upload-logo'].files[0];
      this.logoToUpload = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.logoUrl = reader.result;
      }

      if (this.project) {
        this.dataChanged = {};
        let formData = new FormData;
        formData.append('logo', this.logoToUpload);
        this.saveProject(formData, 'logo');
      }
    },
    changeViewType(id) {
      let includes = this.form.types.includes(id);
      if (includes) {
        this.form.types.splice(this.form.types.indexOf(id), 1);
      } else {
        this.form.types.push(id);
      }
      if (this.project) {
        this.dataChanged = {};
        let formData = new FormData;
        formData.append('types', this.form.types);
        this.saveProject(formData, 'types');
      }
    },
    addProject() {
      let formData = new FormData;
      for (var key in this.form) {
        if (key === 'usersPermissions') {

        } else if (this.form[key] !== null && this.form[key] !== "") {
          formData.append(key, this.form[key]);
        }
      }
      if (this.logoToUpload) {
        formData.append('logo', this.logoToUpload);
      }
      formData = this.addPermissions(formData);
      axios.post('/settings/projects', formData).then(() => {
        location.href = '/settings/projects';
      }).catch((err) => {
        this.error = err.response.data.message;
      });
    },
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (!['name'].includes(key)) {
          continue;
        }
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    saveChanges(e) {
      if (!this.project) {
        this.validate();
      } else {
        if (this.init || !e) {
          return;
        }
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.dataChanged = {};
          let formData = new FormData;
          if (this.form[e.target.id]) {
            formData.append(e.target.id, this.form[e.target.id]);
          }
          this.saveProject(formData, e.target.id);
          this.touched = true;
        }, 200);
      }
    },
    addPermissions(formData) {
      let createTasks = [];
      let editTasks = [];
      let deleteTasks = [];
      for (let i = 0; i < this.form.usersPermissions.length; i++) {
        if (this.form.usersPermissions[i].create_tasks) {
          createTasks.push(this.form.usersPermissions[i].id);
        }
        if (this.form.usersPermissions[i].edit_tasks) {
          editTasks.push(this.form.usersPermissions[i].id);
        }
        if (this.form.usersPermissions[i].delete_tasks) {
          deleteTasks.push(this.form.usersPermissions[i].id);
        }
      }
      formData.append('create_tasks', JSON.stringify(createTasks));
      formData.append('edit_tasks', JSON.stringify(editTasks));
      formData.append('delete_tasks', JSON.stringify(deleteTasks));
      return formData;
    },
    saveProject(formData, field) {
      if (this.form.name) {
        formData.append('name', this.form.name);
        formData.append('users', this.form.users);
        formData = this.addPermissions(formData);
        formData.append('_method', 'PUT');
        axios.post('/settings/projects/' + this.project.id, formData).then(() => {
          this.dataChanged[field] = true;
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    deleteProject() {
      if (confirm("Вы уверены, что хотите удалить проект " + this.form.name + "?")) {
        axios.delete('/settings/projects/' + this.project.id).then(() => {
          location.href = '/settings/projects';
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    deleteUser(id) {
      let user = this.form.users.find(x => x == id);
      this.form.users.splice(this.form.users.indexOf(user), 1);
      let usersSelected = this.usersSelect.getSelected();
      usersSelected.splice(usersSelected.indexOf('' + id), 1);
      this.usersSelect.setSelected(usersSelected);

      let userPermission = this.form.usersPermissions.find(x => x.id == id);
      this.form.usersPermissions.splice(this.form.usersPermissions.indexOf(userPermission), 1);
    }
  },
  watch: {
    'form.users': function(newVal, oldVal) {
      if (this.init) {
        return;
      }
      if ((!this.project && this.form.users.includes('' + this.usersPermissions[0].id))
          || (this.project && this.project.creator_id && this.project.creator_id == this.usersPermissions[0].id
              && this.form.users.includes('' + this.usersPermissions[0].id))
          || (this.project && !this.project.creator_id)) {
        if (oldVal && newVal) {
          if (newVal.length > oldVal.length) {
            let user = this.users.find(x => x.id == newVal.find(x => !oldVal.includes(x)));
            if (!this.usersPermissions.length || (this.usersPermissions.length && (user.id != this.usersPermissions[0].id))) {
              user.create_tasks = true;
              user.edit_tasks = true;
              user.delete_tasks = true;
              this.form.usersPermissions.push(user);
            }
          } else if (newVal.length < oldVal.length) {
            let id = oldVal.find(x => !newVal.includes(x));
            let permission = this.form.usersPermissions.find(x => x.id == id);
            this.form.usersPermissions.splice(this.form.usersPermissions.indexOf(permission), 1);
          }
        } else if (newVal) {
          this.form.usersPermissions = this.users.filter(x => x.id == newVal[0]);
        }
      } else {
        let usersSelected = this.usersSelect.getSelected();
        usersSelected.unshift('' + this.usersPermissions[0].id);
        this.usersSelect.setSelected(usersSelected);
      }
    }
  }
}
</script>