<template>
  <div class="pu-box pu-box--right pu-box--sm is-open" v-click-out-side="closeModal">
    <div class="pu-box__wrap">
      <div class="pu-box__top">
        <div class="pu-task-top__close">
          <button @click="closeModal()" class="btn-close pu-close" type="button">
            <svg class="svg" width="24" height="24">
              <use :href="'/img/sprite-' + appVersion + '.svg#cross-24'"/>
            </svg>
          </button>
        </div>
      </div>

      <div class="pu-box__main">
        <div class="pu-box__body">
          <div class="pu-box__block">
            <div class="form-group">
              <label class="switch toggle toggle--m">
                <input type="checkbox" class="switch__input" v-model="filtersObject.myRequests">
                <span class="switch__cover toggle__cover"></span>

                <span class="switch__label toggle__label">Только мои задачи</span>
              </label>
            </div>
          </div>
          <div class="pu-box__block">
            <div class="form-group">
              <label for="executors" class="form-label">Исполнитель</label>

              <div class="form-field form-field--select form-field--2 form-field--m form-field--primary">
                <select id="executors" ref="executors" v-model="filtersObject.executor" class="form-control">
                  <option v-for="executor in executors" :value="executor.id">{{executor.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="pu-box__block">
            <div class="form-group">
              <label for="initiator" class="form-label">Инициатор</label>

              <div class="form-field form-field--select form-field--2 form-field--m form-field--primary">
                <select id="initiator" ref="initiators" v-model="filtersObject.initiator" class="form-control">
                  <option v-for="initiator in initiators" :value="initiator.id">{{initiator.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="pu-box__footer">
          <div class="pu-box__btns pu-box__btns--right">
            <button @click="resetFilters" class="btn btn--2 btn--m btn--primary" type="button">
              <span class="btn__label">Сброс</span>
            </button>

            <button @click="submitFilters" class="btn btn--1 btn--m btn--primary" type="button">
              <span class="btn__label">Отфильтровать</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import packageInfo  from '../../../../package';
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import SlimSelect from 'slim-select';

export default {
  props: {
    filtersData: Object,
    filters: Object
  },
  directives: {
    clickOutSide,
  },
  data() {
    return {
      appVersion: packageInfo.version,
      myRequests: false,
      executors: null,
      initiators: null,

      filtersObject: {
        viewType: null,
        executor: null,
        initiator: null,
        myRequests: false
      },
    }
  },
  mounted() {
    new SlimSelect({
      select: this.$refs['executors'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });
    new SlimSelect({
      select: this.$refs['initiators'],
      settings: {
        showSearch: false,
        allowDeselect: false
      }
    });

    this.filtersObject = this.filters;
    this.executors = this.filtersData['users'];
    this.initiators = this.filtersData['users'];
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    resetFilters() {
      this.filtersObject = {
        myRequests: null,
        executor: null,
        initiator: null,
        viewType: this.filtersObject.viewType
      };
      this.$emit('submitFilters', this.filtersObject);
    },
    submitFilters() {
      let filter = {};
      for (let parameter in this.filters) {
        if (this.filters[parameter] !== null && this.filters[parameter] !== false && this.filters[parameter] !== 'Все'
            && this.filters[parameter] !== 'null') {
          filter[parameter] = this.filters[parameter];
        }
      }
      this.filtersObject = filter;

      this.$emit('submitFilters', this.filtersObject);
    }
  }
};
</script>