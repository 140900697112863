<template>
  <div class="pu-box__form-comment">
    <div class="form-group">
      <form class="form-field form-field--textarea form-field--2 form-field--m form-field--primary">
        <div class="form-field__side form-field__side--left">
          <label class="form-field__attach file-btn form-btn">
            <input @change="uploadDocument($event)" type="file" multiple ref="upload-documents"
                   :accept="accept" class="file-btn__input">
            <span class="icon icon--s form-icon">
              <svg class="svg" width="24" height="24">
                <use :href="'/img/sprite-' + appVersion + '.svg#attach-24'"/>
              </svg>
            </span>
          </label>
        </div>

        <Editor id="text" v-model="text" class="form-control textarea textarea_vue" @paste="onPaste($event)"
                placeholder="Комментарий" @selectionChange="selectEditor" @load="selectEditorListener" :modules="modules"
                :formats="formats">
          <template v-slot:toolbar>
            <div class="ql-buttons" :class="{'hidden': !showToolbar}">
              <span class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
              </span>
            </div>
          </template>
        </Editor>
      </form>
    </div>
  </div>

  <div class="pu-box__form-files">
    <div v-if="filesErrors" v-html="filesErrors" class="form-caption form-caption--error"></div>
    <div class="list list--sm">
      <div v-for="file in filesToUpload" class="list__item">
        <div class="file-item" :class="{'has-error': file.error, 'in-progress': file.uploading}">
          <div class="file-item__main file-item__main--main">
            <div class="file-item__cover">
              <svg class="svg-clr" width="32" height="32">
                <use :href="'/img/sprite-clr-' + appVersion + '.svg#file-' + getClassName(file.name) + '-32'"/>
              </svg>
            </div>
            <div class="file-item__info">
              <div class="file-item__name">{{file.name}}</div>
              <div class="file-item__description">
                <strong>{{fileSize(file.size)}}</strong>
              </div>
            </div>
          </div>
          <div class="file-item__extra">
            <template v-if="file.uploading">
              <div class="spinner">
                <div class="file-item__name" style="margin-right: 10px">{{file.progress}}%</div>
                <svg class="svg-clr" width="24" height="32">
                  <use :href="'/img/sprite-clr-' + appVersion + '.svg#spinner-24'"/>
                </svg>
              </div>
              <button @click="abortUploading(file)" class="file-item__btn file-item__btn--cancel" type="button">
                <span class="icon icon--s">
                  <svg class="svg" width="16" height="16">
                    <use href="img/sprite.svg#cross-16"></use>
                  </svg>
                </span>
              </button>
            </template>
            <button v-if="!file.uploading" @click="deleteFile(file)" class="file-item__btn" type="button">
              <svg class="svg" width="16" height="16">
                <use :href="'/img/sprite-' + appVersion + '.svg#delete-16'"/>
              </svg>
            </button>
          </div>
        </div>
        <div v-if="file.error" class="form-caption form-caption--error" v-html="file.error"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from 'primevue/editor';
import Quill from 'quill';
import axios from 'axios';
import packageInfo from '../../../../package';
import {focusInput} from '../_Functions/focusInput';

export default {
  components: {
    Editor
  },
  props: {
    user: Object,
    droppedFiles: Array,
    supportedFileTypes: Array,
    supportedMimeTypes: Array,
    maxFileUploadSize: String,
    maxFileUploadSizeInBytes: Number
  },
  data() {
    return {
      accept: null,
      appVersion: packageInfo.version,
      text: null,
      chunksToUpload: [],
      filesToUpload: [],
      filesErrors: null,
      filesToUploadQueue: [],
      filesUploadInProcess: false,
      showToolbar: false,
      offsetX: 0,
      offsetY: 0,
      modules: {
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              handler: () => {this.saveComment()}
            }
          }
        },
        clipboard: {
          matchers: [
            [ Node.ELEMENT_NODE, function(node, delta) {
              let Delta = Quill.import('delta');
              let d = new Delta();
              return delta.compose(d.retain(delta.length(),
                  { color: false,
                    background: false,
                    link: false,
                  }
              ));
            }
            ]
          ]
        }
      },
      formats: [
        'bold',
        'code',
        'italic',
        'strike',
        'underline',
        'header',
        'indent',
        'list',
        'align'
      ]
    }
  },
  mounted() {
    this.accept = [];
    for (let i = 0; i < this.supportedFileTypes.length; i++) {
      this.accept.push('.' + this.supportedFileTypes[i]);
    }
    this.accept = this.accept.join(',');
    focusInput();
  },
  methods: {
    selectEditorListener() {
      const editor = document.getElementsByClassName('ql-editor')[0];
      var offsetDownX = null;
      var offsetDownY = null;
      var offset = null;
      var that = this;
      editor.onmousedown = function(e) {
        offsetDownX = e.offsetX;
        offsetDownY = e.layerY;

        editor.onmouseup = function(e) {
          let offsetUpX = e.offsetX;
          let offsetUpY = e.layerY;
          if (offsetUpX > offsetDownX) {
            offset = offsetDownX;
          } else {
            offset = offsetUpX;
          }
          offset += (Math.abs(offsetUpX - offsetDownX) - 100) / 2;
          if (offset < 0) {
            offset = 0;
          }
          that.offsetX = offset;

          if (offsetUpY < offsetDownY) {
            that.offsetY = offsetUpY - 45;
          } else {
            that.offsetY = offsetDownY - 45;
          }
        }
      }
    },
    selectEditor($event) {
      if ($event.range && $event.range.length && $event.range.length > 0) {
        this.showToolbar = true;
        document.getElementsByClassName('ql-toolbar')[0].style.left = this.offsetX + 'px';
        document.getElementsByClassName('ql-toolbar')[0].style.top = this.offsetY + 'px';
      } else {
        this.showToolbar = false;
      }
    },
    findExistingFile(file) {
      return this.filesToUpload.find(function(existingFile) {
        return (
            existingFile.name         === file.name &&
            existingFile.lastModified === file.lastModified &&
            existingFile.size         === file.size &&
            existingFile.type         === file.type
        )
      })
    },
    validateFileExtension(file) {
      let type = file.type;
      if (type) {
        return this.supportedMimeTypes.includes(type);
      } else {
        return true;
      }
    },
    validateFileSize(file) {
      return file.size < this.maxFileUploadSizeInBytes;
    },
    addFileToUploadList(fileObject) {
      if (this.filesToUpload.length === 10) {
        this.filesErrors = 'К одному комментарию нельзя прикрепить больше 10 файлов';
        return false;
      }
      this.filesErrors = null;

      let file = {name: fileObject.name,
        lastModified: fileObject.lastModified,
        size: fileObject.size,
        type: fileObject.type};

      if (!this.findExistingFile(file)) {
        if (this.validateFileExtension(file) && this.validateFileSize(file)) {
          this.filesToUpload.push(file);
          return file;
        } else {
          let error = [];
          if (!this.validateFileExtension(file)) {
            error.push('Тип файла не поддерживается');
          }
          if (!this.validateFileSize(file)) {
            error.push('Файл слишком большой, максимальный размер ' + this.maxFileUploadSize);
          }
          file.error = error.join('<br>');
          this.filesToUpload.push(file);
          return false;
        }
      }
    },
    uploadDocument() {
      let files = this.$refs['upload-documents'].files;
      for (let i = 0; i < files.length; i++) {
        let file = this.addFileToUploadList(files[i]);
        if (file) {
          let chunks = this.createChunks(files[i]);
          this.uploadFile(chunks, file);
        }
      }
      this.$refs['upload-documents'].value = null;
    },
    onPaste(e) {
      const items = (e.clipboardData || e.originalEvent.clipboardData).items;
      let blob = null;
      for (let item of items) {
        blob = item.getAsFile();
        if (blob) {
          let file = this.addFileToUploadList(blob);
          if (file) {
            let chunks = this.createChunks(blob);
            this.uploadFile(chunks, file);
          }
        }
      }
    },
    getClassName(file) {
      let typeOfFile = file.split('.').pop().toLowerCase();

      const types = ['csv', 'doc', 'docx', 'gif', 'jpg', 'pdf', 'png', 'rar', 'tiff', 'xls', 'xml', 'zip'];

      if (typeOfFile === 'xlsx') {
        typeOfFile = 'xls';
      } else if (typeOfFile === 'jpeg') {
        typeOfFile = 'jpg';
      }
      if (types.includes(typeOfFile)) {
        return typeOfFile;
      } else {
        return 'file';
      }
    },
    fileSize(size) {
      var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['б', 'Кб', 'Мб', 'Гб', 'Тб'][i];
    },
    createChunks(file) {
      let size = 1048576, fileChunks = Math.ceil(file.size / size);
      let chunks = [];
      for (let i = 0; i < fileChunks; i++) {
        chunks.push(file.slice(
            i * size, Math.min(i * size + size, file.size), file.type
        ));
      }
      return chunks;
    },
    ajaxUploadFile(i, formData, file, chunks) {
      file = this.findExistingFile(file);
      let indexOfFile = this.filesToUpload.indexOf(file);
      this.filesToUpload[indexOfFile].controller = new AbortController();
      const signal = this.filesToUpload[indexOfFile].controller.signal;

      let that = this;
      return new Promise(function(resolve, reject) {
        axios({
          method: 'POST',
          data: formData,
          url: 'upload-request-comment-file',
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          onUploadProgress: event => {
            file = that.findExistingFile(file);
            let indexOfFile = that.filesToUpload.indexOf(file);
            if (indexOfFile > -1) {
              that.filesToUpload[indexOfFile].progress = Math.floor((event.progress * 100) / chunks.length + (100 / chunks.length) * i);
            }
          }
        }, {signal}).then(response => {
          if (i === 0) {
            file = that.findExistingFile(file);
            let indexOfFile = that.filesToUpload.indexOf(file);
            that.filesToUpload[indexOfFile].hashname = response.data.hashname;
            if (chunks.length === 1) {
              that.filesToUpload[indexOfFile].uploading = null;
              if (response.data.error) {
                that.filesToUpload[indexOfFile].error = response.data.error;
              } else {
                that.filesToUpload[indexOfFile].link = response.data.link;
              }
            }
          } else if (i === (chunks.length - 1)) {
            file = that.findExistingFile(file);
            let indexOfFile = that.filesToUpload.indexOf(file);
            that.filesToUpload[indexOfFile].uploading = null;
            if (response.data.error) {
              that.filesToUpload[indexOfFile].error = response.data.error;
            } else {
              that.filesToUpload[indexOfFile].link = response.data.link;
            }
          }
          resolve();
        }).catch(error => {
          reject();
        });
      });
    },
    async uploadFile(chunks, file) {
      this.filesToUploadQueue.push(file);
      this.filesUploadInProcess = true;
      this.$emit('filesUploadInProcess', this.filesUploadInProcess);

      for (let i = 0; i < chunks.length; i++) {
        if (!chunks.length) {
          break;
        }
        file = this.findExistingFile(file);
        let indexOfFile = this.filesToUpload.indexOf(file);
        if (indexOfFile > -1) {
          let formData = new FormData;
          if (i === 0) {
            this.filesToUpload[indexOfFile].extension = file.name.split('.').pop().toLowerCase();
            formData.set('extension', file.name.split('.').pop().toLowerCase());
            this.filesToUpload[indexOfFile].uploading = true;
            this.filesToUpload[indexOfFile].progress = 0;
            if (chunks.length === 1) {
              formData.set('is_last', true);
            }
          } else if (i === (chunks.length - 1)) {
            formData.set('is_last', true);
          }

          if (this.filesToUpload[indexOfFile].hashname) {
            formData.set('hashname', this.filesToUpload[indexOfFile].hashname);
          }

          formData.set('file', chunks[i]);

          await this.ajaxUploadFile(i, formData, file, chunks);
        }
      }

      let indexOfFile = this.filesToUploadQueue.indexOf(file);
      if (indexOfFile > -1) {
        this.filesToUploadQueue.splice(indexOfFile, 1);
      }
      if (this.filesToUploadQueue.length === 0) {
        this.filesUploadInProcess = false;
        this.$emit('filesUploadInProcess', this.filesUploadInProcess);
      }
    },
    ajaxDeleteFile(file) {
      let indexOfFile = this.filesToUpload.indexOf(file);
      if (indexOfFile > -1) {
        this.filesToUpload.splice(indexOfFile, 1);
      }

      if (file.hashname) {
        axios.post('/delete-uploading-request-comment-file', {'hashname': file.hashname}).then((res) => {});
      }
      if (this.filesToUpload.length < 10) {
        this.filesErrors = null;
      }
      indexOfFile = this.filesToUploadQueue.indexOf(file);
      if (indexOfFile > -1) {
        this.filesToUploadQueue.splice(indexOfFile, 1);
      }
      if (this.filesToUploadQueue.length === 0) {
        this.filesUploadInProcess = false;
        this.$emit('filesUploadInProcess', this.filesUploadInProcess);
      }
    },
    abortUploading(file) {
      file = this.findExistingFile(file);
      let indexOfFile = this.filesToUpload.indexOf(file);
      if (indexOfFile > -1 && this.filesToUpload[indexOfFile].hashname) {
        this.filesToUpload[indexOfFile].controller.abort();
        this.ajaxDeleteFile(file);
      }
    },
    deleteFile(file) {
      file = this.findExistingFile(file);
      let indexOfFile = this.filesToUpload.indexOf(file);
      if (indexOfFile > -1) {
        this.ajaxDeleteFile(file);
      }
    },
    saveComment() {
      let files = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        if (this.filesToUpload[i].hashname) {
          files.push(this.filesToUpload[i]);
          if (i === 9) {
            break;
          }
        }
      }
      if ((this.text && this.text !== '' && this.text.trim().length) || files.length) {
        let comment = {};
        if (this.text) {
          comment.text = this.text;
        }
        comment.files = files;
        this.text = null;

        this.filesToUpload = [];
        this.filesErrors = null;
        this.showToolbar = false;
        this.$emit('createComment', comment);
      }
    },
  },
  watch: {
    droppedFiles(files) {
      for (let i = 0; i < files.length; i++) {
        let file = this.addFileToUploadList(files[i]);
        if (file) {
          let chunks = this.createChunks(files[i]);
          this.uploadFile(chunks, file);
        }
      }
    }
  }
};
</script>