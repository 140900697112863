<template>
  <div v-if="ready" class="pu-wrap pu-wrap-request-form is-open">
    <div class="pu-box pu-box--right pu-box--lg is-open" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div class="pu-box__wrap">
        <div :class="{'drag-over': isDropEvent}" class="pu-box__drop-zone drop-zone">
          <div class="drop-zone__wrap">
            <div class="drop-zone__message">
              <div class="drop-zone__message-icon">
                <svg class="svg" width="24" height="24">
                  <use :href="'/img/sprite-' + appVersion + '.svg#upload-24'"/>
                </svg>
              </div>
              <div class="drop-zone__message-title">Перетащите файлы сюда</div>
              <div class="drop-zone__message-text">
                Поддерживаемые файлы: <strong v-html="supportedFileTypes.join(', ')"></strong><br>Размер не более {{maxFileUploadSize}}
              </div>
            </div>
          </div>
        </div>
        <div class="pu-box__top">
          <div class="pu-task-top">
            <div class="pu-task-top__wrap">
              <div class="pu-task-top__close">
                <button @click="closeModal" class="btn btn--4 btn--m btn--gray btn--icon pu-close" type="button">
                <span class="btn__icon">
                  <span class="icon icon--m">
                    <svg class="svg" width="24" height="24">
                      <use :href="'/img/sprite-' + appVersion + '.svg#cross-24'"/>
                    </svg>
                  </span>
                </span>
                </button>
              </div>
              <div class="pu-task-top__info">
                <div class="task-head">
                <span class="task-head__number">
                  <strong v-if="!request">№{{createData.lastRequestId}}</strong>
                  <strong v-if="request">№{{request.id}}</strong>
                </span>
                </div>
              </div>
              <div class="pu-task-top__ctrl">
                <button v-if="request && request.delete" @click="deleteRequest" class="btn btn--4 btn--s btn--gray btn--icon" type="button">
                <span class="btn__icon">
                  <span class="icon icon--s">
                    <svg class="svg" width="16" height="16">
                      <use href="/img/sprite.svg#delete-16" />
                    </svg>
                  </span>
                </span>
                </button>

                <button v-if="!request" @click="addRequest" :disabled="filesUploadInProcess" class="btn btn--1 btn--m btn--primary" type="button">
                  <span class="btn__label">Добавить</span>
                </button>

                <button v-if="request" @click="editRequest" class="btn btn--1 btn--m btn--primary" type="button">
                  <span class="btn__label">Сохранить</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="pu-box__main">
          <div class="pu-box__body">
            <div class="task-teas">
              <div class="task-teas__header">
                <div class="form-group">
                  <label for="name" class="form-label">Название задачи</label>
                  <div class="form-field form-field--textarea form-field--2 form-field--l form-field--primary">
                    <textarea @keydown.enter.prevent="save" id="name" ref="name" v-model="form.name" type="text" class="form-control"
                              @input="resize()" rows="2" placeholder="Название задачи"></textarea>
                  </div>
                </div>
              </div>

              <div class="task-teas__body">
                <div class="task-teas__block">
                  <div class="group">
                    <div class="form-label">Проект</div>
                    <div class="line">
                      <div v-for="project in mostUsedProjects" class="line__item">
                        <button class="project-item btn-item" type="button" @click="selectProject(project.id)"
                                :class="{'is-active': project.id === form.project_id}">
                          <div class="project-item__cover">
                            <svg v-if="project.type === 'personal'" class="svg" width="20" height="20">
                              <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                            </svg>
                            <template v-else>
                              <img v-if="project.logo" :src="project.logo" width="20" height="20" alt="">
                              <template v-else-if="project.emoji">{{project.emoji}}</template>
                              <svg v-else class="svg" width="24" height="24">
                                <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                              </svg>
                            </template>
                          </div>
                          <div class="project-item__name">{{project.name}}</div>
                        </button>
                      </div>
                    </div>
                    <div class="line">
                      <button @click="showMoreProjects" :class="{'is-active': isShowMoreProjects}" class="btn-add" type="button">
                        <span class="icon icon--s">
                          <svg class="svg" width="16" height="16">
                            <use href="/img/sprite.svg#add-16"></use>
                          </svg>
                        </span>
                      </button>
                      <div v-if="isShowMoreProjects" v-for="project in projects" class="line__item">
                        <button class="project-item btn-item" type="button" @click="selectProject(project.id)"
                                :class="{'is-active': project.id === form.project_id}">
                          <div class="project-item__cover">
                            <svg v-if="project.type === 'personal'" class="svg" width="20" height="20">
                              <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
                            </svg>
                            <template v-else>
                              <img v-if="project.logo" :src="project.logo" width="20" height="20" alt="">
                              <template v-else-if="project.emoji">{{project.emoji}}</template>
                              <svg v-else class="svg" width="24" height="24">
                                <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
                              </svg>
                            </template>
                          </div>
                          <div class="project-item__name">{{project.name}}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!project || (project.type !== 'personal')" class="task-teas__block">
                  <div class="group">
                    <div class="form-label">Исполнители</div>
                    <div class="line">
                      <template v-if="!form.project_id">
                        <div class="line__item">
                          <button class="person-item btn-item" type="button" @click="selectExecutor(currentUser.id)"
                                  :class="{'is-active': form.executors.includes(currentUser.id)}">
                            <div class="person-item__photo">
                              <img class="person-item__img" :src="currentUser.photo" width="32" height="32" alt="">
                            </div>
                            <div class="person-item__name">Я</div>
                          </button>
                        </div>
                        <div v-for="user in mostUsedUsers" class="line__item">
                          <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                  :class="{'is-active': form.executors.includes(user.id)}">
                            <div class="person-item__photo">
                              <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                            </div>
                            <div class="person-item__name">{{user.name}}</div>
                          </button>
                        </div>
                        <button @click="showMoreUsers" :class="{'is-active': isShowMoreUsers}" class="btn-add btn-add--circle" type="button">
                          <span class="icon icon--s">
                            <svg class="svg" width="16" height="16">
                              <use href="/img/sprite.svg#add-16"></use>
                            </svg>
                          </span>
                        </button>
                        <div v-if="isShowMoreUsers" v-for="user in users" class="line__item">
                          <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                  :class="{'is-active': form.executors.includes(user.id)}">
                            <div class="person-item__photo">
                              <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                            </div>
                            <div class="person-item__name">{{user.name}}</div>
                          </button>
                        </div>
                      </template>
                      <template v-if="form.project_id">
                        <div v-for="user in projectUsers" class="line__item">
                          <button class="person-item btn-item" type="button" @click="selectExecutor(user.id)"
                                  :class="{'is-active': form.executors.includes(user.id)}">
                            <div class="person-item__photo">
                              <img class="person-item__img" :src="user.photo" width="32" height="32" alt="">
                            </div>
                            <div class="person-item__name">{{user.name}}</div>
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="task-teas__block">
                  <div class="group">
                    <div class="form-label">Приоритет</div>
                    <div class="line">
                      <div v-for="priority in priorities" class="line__item">
                        <button class="priority-item btn-item" type="button" @click="changePriority(priority.id)"
                                :class="{'priority-item--normal': priority.slug !== 'high',
                                'priority-item--hight': priority.slug === 'high',
                                'is-active': priority.id === form.priority_id}">
                          <div class="priority-item__cover">
                            <span class="icon icon--s">
                              <svg class="svg" width="16" height="16">
                                <use href="/img/sprite.svg#urgent-16" />
                              </svg>
                            </span>
                          </div>
                          <div class="priority-item__name">{{ priority.name }}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="task-teas__block">
                  <div class="row">
                    <div class="col col--fourth">
                      <div class="form-group">
                        <label for="start_date" class="form-label">Дата</label>
                        <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                          <VueDatePicker id="start_date" v-model="form.start_date" :min-date="new Date()" locale="ru"
                                         :model-type="'yyyy-MM-dd'" auto-apply :enable-time-picker="false"
                                         :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                         :clearable="true" cancelText="Отмена" selectText="Выбрать" :format="dateFormat"
                                         :highlight="highlightedDates"
                                         input-class-name="form-control"></VueDatePicker>
                        </div>
                      </div>
                    </div>
                    <div v-if="form.start_date" class="col col--fourth">
                      <div class="form-group">
                        <label for="start_time" class="form-label">Время</label>
                        <div class="date-picker form-field form-field--text form-field--2 form-field--m form-field--primary">
                          <VueDatePicker id="start_time" v-model="form.start_time" locale="ru" auto-apply time-picker
                                         :model-type="'HH:mm'"
                                         :teleport="true" :dark="currentUser.setting.theme === 'dark'"
                                         :clearable="true" cancelText="Отмена" selectText="Выбрать"
                                         input-class-name="form-control"></VueDatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pu-box__bottom">
          <request-comment-add-form ref="addComment" v-if="createData" @createComment="createComment"
                                    @filesUploadInProcess="getFilesUploadInProcess"
                                    :droppedFiles="droppedFiles" :supportedMimeTypes="supportedMimeTypes"
                                    :supportedFileTypes="supportedFileTypes" :maxFileUploadSize="maxFileUploadSize"
                                    :maxFileUploadSizeInBytes="maxFileUploadSizeInBytes">
          </request-comment-add-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue';
import RequestCommentAddForm from './RequestCommentAddForm.vue';

export default {
  components: {VueDatePicker, RequestCommentAddForm},
  props: {
    createData: Object,
    editData: Object,
    taskName: String,
  },
  data() {
    return {
      appVersion: packageInfo.version,
      form: {
        name: null,
        priority_id: null,
        project_id: null,
        start_date: null,
        start_time: null,
        executors: []
      },
      allProjects: null,
      project: null,
      currentUser: null,
      images: [],
      isShowMoreProjects: false,
      isShowMoreUsers: false,
      mostUsedProjects: null,
      projects: null,
      mostUsedUsers: null,
      users: null,
      priorities: null,
      projectUsers: null,
      request: null,
      ready: false,
      isDropEvent: false,
      droppedFiles: [],
      answerTo: null,
      supportedMimeTypes: [],
      supportedFileTypes: [],
      maxFileUploadSize: null,
      maxFileUploadSizeInBytes: null,
      dateFormat: null,
      comment: null,
      filesUploadInProcess: false,
      highlightedDates: null
    }
  },
  mounted() {
    const date = ref(new Date());

    this.dateFormat = (date) => {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

    this.highlightedDates = ref([
      "2024-01-01",
      "2024-01-02",
      "2024-01-03",
      "2024-01-04",
      "2024-01-05",
      "2024-01-06",
      "2024-01-07",
      "2024-01-08",
      "2024-01-13",
      "2024-01-14",
      "2024-01-20",
      "2024-01-21",
      "2024-01-27",
      "2024-01-28",
      "2024-02-03",
      "2024-02-04",
      "2024-02-10",
      "2024-02-11",
      "2024-02-17",
      "2024-02-18",
      "2024-02-23",
      "2024-02-24",
      "2024-02-25",
      "2024-03-02",
      "2024-03-03",
      "2024-03-08",
      "2024-03-09",
      "2024-03-10",
      "2024-03-16",
      "2024-03-17",
      "2024-03-23",
      "2024-03-24",
      "2024-03-30",
      "2024-03-31",
      "2024-04-06",
      "2024-04-07",
      "2024-04-13",
      "2024-04-14",
      "2024-04-20",
      "2024-04-21",
      "2024-04-28",
      "2024-04-29",
      "2024-04-30",
      "2024-05-01",
      "2024-05-04",
      "2024-05-05",
      "2024-05-09",
      "2024-05-10",
      "2024-05-11",
      "2024-05-12",
      "2024-05-18",
      "2024-05-19",
      "2024-05-25",
      "2024-05-26",
      "2024-06-01",
      "2024-06-02",
      "2024-06-08",
      "2024-06-09",
      "2024-06-12",
      "2024-06-15",
      "2024-06-16",
      "2024-06-22",
      "2024-06-23",
      "2024-06-29",
      "2024-06-30",
      "2024-07-06",
      "2024-07-07",
      "2024-07-13",
      "2024-07-14",
      "2024-07-20",
      "2024-07-21",
      "2024-07-27",
      "2024-07-28",
      "2024-08-03",
      "2024-08-04",
      "2024-08-10",
      "2024-08-11",
      "2024-08-17",
      "2024-08-18",
      "2024-08-24",
      "2024-08-25",
      "2024-08-31",
      "2024-09-01",
      "2024-09-07",
      "2024-09-08",
      "2024-09-14",
      "2024-09-15",
      "2024-09-21",
      "2024-09-22",
      "2024-09-28",
      "2024-09-29",
      "2024-10-05",
      "2024-10-06",
      "2024-10-12",
      "2024-10-13",
      "2024-10-19",
      "2024-10-20",
      "2024-10-26",
      "2024-10-27",
      "2024-11-03",
      "2024-11-04",
      "2024-11-09",
      "2024-11-10",
      "2024-11-16",
      "2024-11-17",
      "2024-11-23",
      "2024-11-24",
      "2024-11-30",
      "2024-12-01",
      "2024-12-07",
      "2024-12-08",
      "2024-12-14",
      "2024-12-15",
      "2024-12-21",
      "2024-12-22",
      "2024-12-29",
      "2024-12-30",
      "2024-12-31"
    ])

    if (this.createData) {
      this.form.name = this.taskName;
      this.getData(this.createData);
      const urlParams = new URLSearchParams(window.location.search);
      this.project_id = urlParams.get('project_id');
      if (!this.project_id || this.project_id == 'personal') {
        this.project = this.allProjects.find(x => x.type === 'personal');
      } else {
        this.project = this.allProjects.find(x => x.id == this.project_id);
      }

      if (this.project) {
        this.selectProject(this.project.id);
      } else {
        this.project_id = null;
      }
    } else if (this.editData) {
      this.request = this.editData.request;
      this.form.name = this.request.name;
      this.form.priority_id = this.request.priority_id;
      this.form.project_id = this.request.project_id;
      this.form.start_date = this.request.start_date;
      this.form.start_time = this.request.start_time;
      if (this.request.start_date && this.request.start_time) {
        this.form.start_date = this.getTimeZoneDateTime('date');
        this.form.start_time = this.getTimeZoneDateTime('time');
      }
      if (this.request.executors) {
        for (let i = 0; i < this.request.executors.length; i++) {
          this.form.executors.push(this.request.executors[i].id);
        }
      }
      this.getData(this.editData);
      if (this.form.project_id) {
        this.project = this.allProjects.find(x => x.id === this.form.project_id);
        this.projectUsers = this.project.users;
      }
    }
    this.ready = true;
  },
  methods: {
    resize() {
      let element = this.$refs['name'];

      element.style.height = '40px';
      element.style.height = element.scrollHeight + 'px';
    },
    getData(object) {
      this.currentUser = object.currentUser;
      this.allProjects = object.allProjects;
      this.priorities = object.priorities;
      this.mostUsedProjects = object.mostUsedProjects;
      this.projects = object.projects;
      this.mostUsedUsers = object.mostUsedUsers;
      this.users = object.users;
      this.supportedMimeTypes = object.supportedMimeTypes;
      this.supportedFileTypes = object.supportedFileTypes;
      this.maxFileUploadSize = object.maxFileUploadSize;
      this.maxFileUploadSizeInBytes = object.maxFileUploadSizeInBytes;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    showMoreProjects() {
      if (!this.isShowMoreProjects) {
        this.isShowMoreProjects = true;
      } else {
        this.isShowMoreProjects = false;
        if (!this.requests) {
          if (this.projects.find(x => x.id === this.form.project_id)) {
            this.form.project_id = null;
          }
        }
      }
    },
    selectProject(e) {
      if (e !== this.form.project_id) {
        this.form.project_id = e;
        this.project = this.allProjects.find(x => x.id === this.form.project_id);
        this.projectUsers = this.project.users;
      } else {
        this.form.project_id = null;
        this.projectUsers = null;
      }
      this.form.executors = [];
    },
    selectExecutor(id) {
      if (this.form.executors.includes(id)) {
        this.form.executors.splice(this.form.executors.indexOf(id), 1)
      } else {
        this.form.executors.push(id);
      }
    },
    showMoreUsers() {
      if (!this.isShowMoreUsers) {
        this.isShowMoreUsers = true;
      } else {
        this.isShowMoreUsers = false;
        if (!this.request) {
          this.form.executors = this.form.executors.filter(x => !this.users.map(a => a.id).includes(x));
        }
      }
    },
    changePriority(e) {
      if (this.form.priority_id === e) {
        this.form.priority_id = null;
      } else {
        this.form.priority_id = e
      }
    },
    fileSize(size) {
      var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['б', 'Кб', 'Мб', 'Гб', 'Тб'][i];
    },
    getUtcDateTime(type) {
      let date = new Date(this.form.start_date + ' ' + this.form.start_time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        date.setHours(date.getHours() - Math.abs(timezone));
      } else if (timezone > 0) {
        date.setHours(date.getHours() + Math.abs(timezone));
      }
      if (type === 'date') {
        return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
      } else {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2);
      }
    },
    getTimeZoneDateTime(type) {
      let date = new Date(this.form.start_date + ' ' + this.form.start_time);
      let timezone = new Date().getTimezoneOffset();
      timezone /= 60;
      if (timezone < 0) {
        date.setHours(date.getHours() + Math.abs(timezone));
      } else if (timezone > 0) {
        date.setHours(date.getHours() - Math.abs(timezone));
      }
      if (type === 'date') {
        return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
      } else {
        return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      }
    },
    createRequestFormData() {
      let formData;
      formData = new FormData;
      for (var key in this.form) {
        if (key === 'executors') {
          for (let i = 0; i < this.form.executors.length; i++) {
            formData.append('executors[]', this.form.executors[i]);
          }
        } else {
          if (this.form[key] !== null && this.form[key] !== "") {
            if (this.form.start_date && this.form.start_time && ['start_date', 'start_time'].includes(key)) {
              if (key === 'start_date') {
                formData.append(key, this.getUtcDateTime('date'));
              } else {
                formData.append(key, this.getUtcDateTime('time'));
              }
            } else {
              formData.append(key, this.form[key]);
            }
          }
        }
      }

      return formData;
    },
    addRequest() {
      if (this.form.name && !this.filesUploadInProcess) {
        this.$refs.addComment.saveComment();
        let formData = this.createRequestFormData();
        if (this.comment) {
          if (this.comment.text) {
            formData.append('text', this.comment.text);
          }
          for (let i = 0; i < this.comment.files.length; i++) {
            if (i < 10) {
              formData.append('files[]', JSON.stringify(this.comment.files[i]));
            } else {
              break;
            }
          }
        }

        axios.post('/requests', formData).then(() => {
          this.$emit('closeModal');
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    editRequest() {
      if (this.form.name) {
        let formData = this.createRequestFormData();
        formData.append('_method', 'PUT');

        axios.post('/requests/' + this.request.id, formData).then(() => {
          this.$emit('closeModal');
        }).catch((err) => {
          this.error = err.response.data.error;
        });
      }
    },
    createComment(e) {
      this.comment = e;
    },
    getFilesUploadInProcess(e) {
      this.filesUploadInProcess = e;
    },
    save() {
      if (!this.request) {
        if (!this.filesUploadInProcess) {
          this.addRequest();
        }

      } else {
        this.editRequest();
      }
    },
    deleteRequest() {
      if (confirm('Удалить эту задачу?')) {
        axios.delete('/requests/' + this.request.id).then((res) => {
          this.$emit('closeModal');
        })
      }
    },
    dragover(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = true;
    },
    dragleave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false
    },
    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isDropEvent = false;
      this.droppedFiles = event.dataTransfer.files;
    },
  }
};
</script>