<template>
  <div class="ext-block">
    <div class="ext-block__wrap">
      <div class="login-box">
        <div class="login-box__top">
          <div class="login-box__logo">
            <div class="logo">
              <img src="/img/logo-icon.svg" width="64" height="71" alt="" class="logo__icon">
              <svg class="logo__label" width="161" height="40">
                <use :href="'/img/sprite-' + appVersion + '.svg#logo-label'"/>
              </svg>
            </div>
          </div>

          <div class="login-box__switch">
            <div class="seg-nav seg-nav--s seg-nav--wide">
              <ul class="seg-nav__list">
                <li class="seg-nav__item">
                  <label class="seg-nav__switch">
                    <input v-model="show" type="radio" name="auth-way" checked value="email">
                    <span class="seg-nav__value">Эл. почта</span>
                  </label>
                </li>
                <li class="seg-nav__item">
                  <label class="seg-nav__switch">
                    <input v-model="show" type="radio" name="auth-way" value="sms">
                    <span class="seg-nav__value">Телефон</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="login-box__main">
          <login-by-email v-if="show === 'email'" :error="error" @submit="login($event, '/auth/email')"></login-by-email>
          <login-by-sms v-if="show === 'sms'" :errorCode="error" @submit="login($event, '/auth/sms')"></login-by-sms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import packageInfo  from '../../../../package';
import LoginByEmail from './LoginByEmail.vue';
import LoginBySms from './LoginBySms.vue';
import VueCookies from 'vue-cookies';

export default {
  components: {LoginByEmail, LoginBySms},
  data() {
    return {
      appVersion: packageInfo.version,
      show: 'email',
      error: null,
    };
  },
  methods: {
    login($event, url) {
      axios.post(url, $event).then((res) => {
        VueCookies.set('jwt-token', res.data.token, '2m');
        location.href = '/';
      }).catch((err) => {
        this.error = err.response.data.error;
      });
    },
  },
  watch: {
    show: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.error = null;
      }
    }
  }
};
</script>