<template>
  <div @scroll="handleScroll" class="main__wrap">
    <div class="main__content content">
      <div class="content__top">
        <div class="title">Пользователи</div>
      </div>
      <div v-if="users.length > 0" class="content__main">
        <div class="content__body">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th>ФИО</th>
                <th>E‑mail</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="user in usersList">
                <td>
                  <a :href="'/settings/users/' + user.id" class="col-main col-link">
                  <span class="staff">
                    <div class="staff__cover">
                      <img class="staff__ava" :src="user.photo" width="32" height="32" alt="">
                    </div>
                    <span class="staff__label">{{user.name}}</span>
                  </span>
                  </a>
                </td>
                <td><span class="col-extra">{{user.email}}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="users.length === 0" class="content__main content__main--center">
        <div class="content__body">
          <div class="plug-box">
            <div class="plug-box__cover">
              <img src="/img/plug-no-tasks.svg" width="574" height="231" alt="" class="plug-box__img">
            </div>

            <div class="plug-box__text">Активных пользователей нет</div>
          </div>
        </div>
      </div>

      <div class="content__bottom">
        <a href="/settings/users/create" class="btn btn--4 btn--m btn--primary">
          <span class="btn__label">+ Добавить сотрудника</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    users: Array
  },
  data() {
    return {
      usersList: this.users,
      page: 1,
      isScrollEnabled: true,
    }
  },
  methods: {
    scrollUsers() {
      axios.get('/settings/users?page=' + this.page).then((res) => {
        this.usersList = this.usersList.concat(res.data);
        this.isScrollEnabled = res.data.length;
      })
    },
    handleScroll(ev) {
      if (this.isScrollEnabled && ev.target.scrollTop >= (ev.target.scrollHeight - ev.target.offsetHeight - 100)) {
        this.page++;
        this.isScrollEnabled = false;
        this.scrollUsers();
      }
    }
  }
};
</script>