<template>
  <div @scroll="handleScroll" class="main__wrap">
    <div class="main__content content">
      <div class="content__top">
        <div class="content__filter">
          <div class="line-filter">
            <div class="line-filter__item">
              <div class="seg-nav seg-nav--s">
                <ul class="seg-nav__list">
                  <li v-for="type in viewTypes" class="seg-nav__item">
                    <label class="seg-nav__switch">
                      <input :class="type.name === viewType ? 'active' : ''" type="radio" v-model="viewType" :value="type.name">
                      <span class="seg-nav__value">{{type.description}}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="viewType === 'list'" class="line-filter__item line-filter__item--mla">
              <div class="form-group">
                <div class="form-field form-field--text form-field--2 form-field--s form-field--primary">
                  <div class="form-field__side form-field__side--left">
                    <span class="icon icon--s form-icon">
                      <svg class="svg" width="16" height="16">
                        <use :href="'/img/sprite-' + appVersion + '.svg#search-16'"/>
                      </svg>
                    </span>
                  </div>

                  <input @keyup="searchRequestsKeyUp" v-model="search" type="text" class="form-control" placeholder="Поиск по задачам">
                </div>
              </div>
            </div>
            <div v-if="viewType !== 'diary'" class="line-filter__item" :class="{'line-filter__item--mla': viewType !== 'list'}">
              <button @click="openFilters" class="btn btn--4 btn--s btn--primary btn--icon pu-link" type="button">
              <span class="btn__icon">
                <span class="icon icon--m">
                  <svg class="svg" width="24" height="24">
                    <use :href="'/img/sprite-' + appVersion + '.svg#filter-24'"/>
                  </svg>
                </span>
              </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loaded && (requests.length > 0 || Object.keys(requests).length)" class="content__main">
        <div class="content__body">
          <requests-list v-if="viewType === 'list'" :requests="requests" :archiveRequests="archiveRequests" :project_id="project_id" @showModal="showRequestModal"></requests-list>
          <requests-board v-if="viewType === 'board'" :steps="requests" @changeStatus="changeStatus" @showModal="showRequestModal"></requests-board>
          <requests-diary v-if="viewType === 'diary'" :days="requests" @changeStartDate="changeStartDate" @showModal="showRequestModal"></requests-diary>
        </div>
      </div>

      <div v-if="loaded && requests.length === 0" class="content__main content__main--center">
        <div class="content__body">
          <div class="plug-box">
            <div class="plug-box__cover">
              <img src="/img/plug-no-tasks.svg" width="574" height="231" alt="" class="plug-box__img">
            </div>

            <div class="plug-box__text">Активных задач нет</div>
          </div>
        </div>
      </div>
    </div>

    <request-modal v-if="isShowRequestModal" :request="request" :user="user" @closeModal="closeRequestModal()"
                   @showRequestEditModal="showRequestEditForm" class="pu-wrap"></request-modal>

    <request-error-modal v-if="isShowRequestErrorModal" :requestId="request_id" :error="error" @closeModal="closeRequestErrorModal()" class="pu-wrap"></request-error-modal>

    <requests-filters-modal v-if="isShowFiltersModal" :filters="filters" :filtersData="filtersData"
                            @closeModal="closeFilters" @submitFilters="submitFilters" class="pu-wrap"></requests-filters-modal>

    <request-form-modal v-if="isShowRequestEditForm" :editData="editData" @closeModal="closeRequestEditFormModal"></request-form-modal>
  </div>
  <div v-if="!loaded" class="preloader preloader--page is-visible">
    <div class="preloader__spinner">
      <svg class="preloader-cover" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#006DFF" class="spinner_LWk7" x="1.5" y="1.5" rx="1" width="9" height="9" />
        <rect fill="#368CFF" class="spinner_yOMU" x="13.5" y="1.5" rx="1" width="9" height="9" />
        <rect fill="#006DFF" class="spinner_KS4S" x="13.5" y="13.5" rx="1" width="9" height="9" />
        <rect fill="#368CFF" class="spinner_zVee" x="1.5" y="13.5" rx="1" width="9" height="9" />
      </svg>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import Echo from "laravel-echo";
import axios from 'axios';
import RequestsList from './RequestsList.vue';
import RequestsFiltersModal from './RequestsFiltersModal.vue';
import VueCookies from 'vue-cookies';
import RequestModal from './RequestModal.vue';
import RequestsBoard from './RequestsBoard.vue';
import RequestsDiary from './RequestsDiary.vue';
import RequestFormModal from './RequestFormModal.vue';
import RequestErrorModal from './RequestErrorModal.vue';
import {debounce} from '../_Functions/debounce';

export default {
  components: {RequestsBoard, RequestModal, RequestsFiltersModal, RequestsList, RequestFormModal, RequestErrorModal, RequestsDiary},
  props: {
    viewTypes: Array,
    project: Object,
    user: Object,
    userSettings: Object
  },
  data() {
    return {
      appVersion: packageInfo.version,
      echoServer: null,
      editData: null,
      isShowFiltersModal: false,
      isShowRequestModal: false,
      isShowRequestEditForm: false,
      isShowRequestErrorModal: false,
      isScrollEnabled: true,
      filters: {
        viewType: null,
        executor: null,
        initiator: null,
        myRequests: false,
      },
      project_id: null,
      filtersData: null,
      loaded: false,
      page: 1,
      request: null,
      request_id: null,
      requests: [],
      viewType: null,
      authToken: null,
      error: null,
      debounceFunction: null,
      search: null,
      scrolledToArchive: false,
      archiveRequests: []
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');
    this.request_id = urlParams.get('id');

    this.viewType = this.userSettings.requests_view_type;
    if (!this.project_id && this.viewType === 'board') {
      this.viewType = 'list';
    }

    let viewType = this.viewTypes.find(x => x.name === this.viewType);
    if (!viewType) {
      this.viewType = 'list';
    }

    this.filters.viewType = this.viewType;

    this.filters.executor = this.userSettings.requests_executor_id;
    this.filters.initiator = this.userSettings.requests_initiator_id;
    this.filters.myRequests = this.userSettings.my_requests;

    this.debounceFunction = debounce(() => this.searchRequests(), 500)

    this.authToken = VueCookies.get('jwt-token');

    let self = this;
    this.echoServer = new Echo({
      broadcaster: "socket.io",
      host: window.location.hostname,
      transports: ["websocket", "poling"],
      auth: {
        headers: {
          Authorization: "Bearer " + this.authToken
        }
      }
    });
    this.echoServer
    .private(process.env.BROADCAST_CHANNELS_PREFIX + "_requests_update." + this.user.id)
    .listen("RequestsBroadcast", function (e) {
      if (!self.search) {
        self.getRequest(e);
      }
    });

    this.echoServer.connector.socket.on('connect', function () {
      self.getRequests();
    });

    if (this.request_id) {
      this.showRequestModal(this.request_id);
    }
  },
  methods: {
    createGetParameters() {
      let options = {};
      for (let parameter in this.filters) {
        if (this.filters[parameter] !== null && this.filters[parameter] !== false && this.filters[parameter] !== 'Все') {
          options[parameter] = this.filters[parameter];
        }
      }
      if (this.project_id) {
        options['project_id'] = this.project_id;
      }
      if (this.search) {
        options['search'] = this.search;
      }
      if (this.scrolledToArchive && this.viewType === 'list') {
        options['archive'] = 'true';
      }
      if (this.viewType === 'diary') {
        let timezone = new Date().getTimezoneOffset();
        timezone /= 60;
        if (timezone !== 0) {
          options['hours'] = timezone;
        }
      }

      return options;
    },
    numWord(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if(value > 10 && value < 20) {return words[2]}
      if(num > 1 && num < 5) {return words[1]}
      if(num == 1) {return words[0]}
      return words[2];
    },
    checkRequestBeforePush(push, request) {
      if (this.project_id) {
        if (request.project_id != this.project_id && this.project_id !== 'personal') {
          push = false;
        }
      }
      if (this.filters.executor) {
        if (request.executorsArray && !request.executorsArray.includes(this.filters.executor)) {
          push = false;
        } else if (!request.executorsArray) {
          push = false;
        }
      }
      if (this.filters.initiator) {
        if (request.initiator_id != this.filters.initiator) {
          push = false;
        }
      }
      if (this.filters.myRequests) {
        if (request.executorsArray && !request.executorsArray.includes(this.user.id)) {
          push = false;
        } else if (!request.executorsArray) {
          push = false;
        }
      }
      return push;
    },
    checkRequestFromBroadcast(e, requests, archive = null) {
      if (this.viewType === 'diary') {
        this.getRequests();
      } else {
        if (e.type === 'store' && this.viewType === 'list') {
          let push = true;
          push = this.checkRequestBeforePush(push, e.request);
          if (push) {
            requests.unshift(e.request);
          }

        } else if ((e.type === 'store' && this.viewType === 'board') || e.type === 'change-status' || e.type === 'update') {
          if (this.viewType === 'board' && (e.type === 'store' || e.type === 'change-status')) {
            let indexOfStep;
            let request;
            let amount;
            if (e.type !== 'store') {
              let prevStep = this.requests.find(x => x.id === e.request.prev_step_id);
              let indexOfStep = this.requests.indexOf(prevStep);
              let request = this.requests[indexOfStep].requests.find(x => x.id === e.request.id);
              let amount = this.requests[indexOfStep].requests.length;
              this.requests[indexOfStep].amount = amount + ' ' + this.numWord(amount, ['задача', 'задачи', 'задач']);
              if (request) {
                let index = this.requests[indexOfStep].requests.indexOf(request);
                this.requests[indexOfStep].requests.splice(index, 1);
              }
            }

            let step = this.requests.find(x => x.id === e.request.step_id);
            indexOfStep = this.requests.indexOf(step);
            request = this.requests[indexOfStep].requests.find(x => x.id === e.request.id);
            if (!request) {
              let push = true;
              push = this.checkRequestBeforePush(push, e.request);
              if (push) {
                this.requests[indexOfStep].requests.unshift(e.request);
              }

            } else {
              let index = this.requests[indexOfStep].requests.indexOf(request);
              this.requests[indexOfStep].requests[index] = e.request;
            }
            amount = this.requests[indexOfStep].requests.length;
            this.requests[indexOfStep].amount = amount + ' ' + this.numWord(amount, ['задача', 'задачи', 'задач']);
          } else {
            let request = requests.find(x => x.id === e.request.id);
            if (request) {
              let index = requests.indexOf(request);
              if (this.filters.myRequests && e.request.executorsArray && !e.request.executorsArray.includes(this.user.id)) {
                requests.splice(index, 1);
              } else if (this.filters.executor && e.request.executorsArray && !e.request.executorsArray.includes(this.filters.executor)) {
                requests.splice(index, 1);
              } else {
                if ((e.request.archive && !archive) || (!e.request.archive && archive)) {
                  requests.splice(index, 1);
                } else {
                  requests[index] = e.request;
                }
              }

            } else {
              if (this.filters.myRequests) {
                if (e.request.executorsArray && e.request.executorsArray.includes(this.user.id)) {
                  requests.unshift(e.request);
                }
              } else if (this.filters.executor) {
                if (e.request.executorsArray && e.request.executorsArray.includes(this.filters.executor)) {
                  requests.unshift(e.request);
                }
              }
            }
          }

        } else if (e.type === 'delete') {
          let request = requests.find(x => x.id === e.request.id);
          if (request) {
            let index = requests.indexOf(request);

            if (this.viewType === 'board') {
              let step = this.requests.find(x => x.id === e.request.step_id);
              let indexOfStep = this.requests.indexOf(step);

              requests.splice(index, 1);

              let amount = this.requests[indexOfStep].requests.length;
              this.requests[indexOfStep].amount = amount + ' ' + this.numWord(amount, ['задача', 'задачи', 'задач']);
            } else {
              requests.splice(index, 1);
            }
          }
        }
      }
    },
    changeStatus(e) {
      this.requests = e;
    },
    changeStartDate(e) {
      this.requests = e;
    },
    getRequest(e) {
      if (this.viewType === 'board') {
        let step = this.requests.find(x => x.id === e.request.step_id);
        let indexOf = this.requests.indexOf(step);
        if (indexOf >= 0) {
          this.checkRequestFromBroadcast(e, this.requests[indexOf].requests);
        }

      } else {
        this.checkRequestFromBroadcast(e, this.requests, false);
        if (this.viewType === 'list' && e.type === 'change-status') {
          this.checkRequestFromBroadcast(e, this.archiveRequests, true);
        }
      }
    },
    getRequests() {
      axios.get('/requests?page=' + this.page, {params: this.createGetParameters()}).then((res) => {
        this.requests = res.data;
        this.loaded = true;
        if (this.viewType === 'list' && res.data.length < 20 && !this.scrolledToArchive) {
          this.page = 1;
          this.scrolledToArchive = true;
          this.scrollRequests();
        }
      })
    },
    scrollRequests() {
      if (this.viewType !== 'list') {
        return;
      }
      axios.get('/requests?page=' + this.page, {params: this.createGetParameters()}).then((res) => {
        if (!this.scrolledToArchive) {
          this.requests = this.requests.concat(res.data);
        } else {
          this.archiveRequests = this.archiveRequests.concat(res.data);
        }

        if (!this.search && (!res.data.length || res.data.length < 20) && !this.scrolledToArchive) {
          this.page = 1;
          this.scrolledToArchive = true;
          this.scrollRequests();
        } else {
          this.isScrollEnabled = res.data.length;
        }
      })
    },
    handleScroll(ev) {
      if (this.viewType === 'list' && this.isScrollEnabled && ev.target.scrollTop >= (ev.target.scrollHeight - ev.target.offsetHeight - 100)) {
        this.page++;
        this.isScrollEnabled = false;
        this.scrollRequests();
      }
    },
    openFilters() {
      axios.get('/get-requests-filters').then((res) => {
        this.filtersData = res.data;
        this.isShowFiltersModal = true;
      })
    },
    closeFilters() {
      this.isShowFiltersModal = false;
    },
    getFiltersFormData() {
      let formData = new FormData;
      if (this.filters.myRequests) {
        formData.append('my_requests', this.filters.myRequests);
      } else {
        formData.append('my_requests', 'false');
      }
      if (this.filters.executor && this.filters.executor !== 'Все') {
        formData.append('requests_executor_id', this.filters.executor);
      }
      if (this.filters.initiator && this.filters.initiator !== 'Все') {
        formData.append('requests_initiator_id', this.filters.initiator);
      }

      return formData;
    },
    submitFilters(e) {
      this.loaded = false;
      this.filters = e;
      this.isShowFiltersModal = false;
      let formData = this.getFiltersFormData();

      axios.post('/change-user-settings', formData).then((res) => {
        this.page = 1;
        this.isScrollEnabled = true;
        this.archiveRequests = [];
        this.scrolledToArchive = false;
        this.getRequests();
      });
    },
    showRequestModal(id) {
      this.request_id = id;
      this.isShowRequestModal = false;
      this.isShowRequestErrorModal = false;
      this.request = null;
      this.error = null;
      axios.get('/get-request-modal/' + id).then((res) => {
        this.request = res.data;
        if (this.project_id) {
          window.history.pushState(null, null, '?project_id=' + this.project_id + '&id=' + this.request_id);
        } else {
          window.history.pushState(null, null, '?id=' + this.request_id);
        }

        document.title = 'Задача ' + this.request.name + ' | Кубрик';

        if (this.viewType === 'board') {
          for (let i = 0; i < this.requests.length; i++) {
            let currentRequest = this.requests[i].requests.find(x => x.id == id);
            if (currentRequest) {
              this.requests[i].requests[this.requests[i].requests.indexOf(currentRequest)].unread_comments_amount = 0;
            }
          }
        } else if (this.viewType === 'list') {
          let currentRequest = this.requests.find(x => x.id == id);
          if (currentRequest) {
            this.requests[this.requests.indexOf(currentRequest)].unread_comments_amount = 0;
          }
        }
        this.isShowRequestModal = true;
      }).catch((err) => {
        this.error = err.response.data.message;
        this.isShowRequestErrorModal = true;
      });
    },
    closeModal() {
      if (this.project_id) {
        window.history.pushState(null, null, '?project_id=' + this.project_id);
        document.title = 'Задачи проекта ' + this.project.name + ' | Кубрик';
      } else {
        window.history.pushState(null, null, '/requests');
        document.title = 'Все задачи | Кубрик';
      }
    },
    closeRequestModal() {
      this.closeModal();
      this.isShowRequestModal = false;
    },
    closeRequestErrorModal() {
      window.history.pushState(null, null, '/requests');
      this.isShowRequestErrorModal = false;
    },
    showRequestEditForm() {
      axios.get('/requests/' + this.request.id + '/edit').then((res) => {
        this.isShowRequestModal = false;
        this.editData = res.data;
        this.isShowRequestEditForm = true;
      })
    },
    closeRequestEditFormModal() {
      this.closeModal();
      this.isShowRequestEditForm = false;
    },
    searchRequestsKeyUp() {
      this.debounceFunction();
    },
    searchRequests() {
      if (!this.search || (this.search && this.search.length > 2)) {
        this.page = 1;
        this.isScrollEnabled = true;
        this.archiveRequests = [];
        this.scrolledToArchive = false;
        this.loaded = false;
        this.getRequests();
      }
    }
  },
  watch: {
    viewType(newVal, oldVal) {
      this.page = 1;
      this.isScrollEnabled = true;
      this.archiveRequests = [];
      this.scrolledToArchive = false;
      if (oldVal) {
        this.loaded = false;
        this.filters.viewType = newVal;
        let formData = this.getFiltersFormData();
        formData.append('requests_view_type', this.filters.viewType);
        axios.post('/change-user-settings', formData).then((res) => {
          this.getRequests();
        });
      }
    }
  },
  beforeUnmount() {
    this.echoServer.leave(process.env.BROADCAST_CHANNELS_PREFIX + "_requests_update." + this.user.id);
  }
};
</script>