<template>
  <div class="main__wrap">
    <form class="main__content content content--wide">
      <div class="content__top">
        <div class="title">Пользователь</div>
      </div>

      <div class="content__main">
        <div class="content__body">
          <div class="content__block">
            <div class="row row--aife">
              <template v-if="imageToCrop">
                <div class="col">
                  <cropper style="width: 500px" ref="cropper" :stencil-props="{aspectRatio: 1}" :src="imageToCrop"/>
                </div>
                <div class="col">
                  <button type="button" @click="cropSelectedImage" class="btn btn--1 btn--m btn--primary">Сохранить</button>
                </div>
              </template>
              <div class="col">
                <label class="ava-file"><span v-if="dataChanged['photo']" class="q-note q-note--saved">Сохранено</span>
                  <input type="file" @change="uploadPhoto($event)" class="ava-file__input visually-hidden" placeholder="Добавить файлы"
                         ref="upload-photo" accept=".png,.jpg,.jpeg,.bmp">
                  <div class="ava-file__main">
                    <span class="ava-file__cover">
                      <span class="icon icon--m">
                        <svg class="svg" width="24" height="24">
                          <use :href="'/img/sprite-' + appVersion + '.svg#camera-24'"/>
                        </svg>
                      </span>
                      <img :src="photoUrl" v-if="photoUrl">
                    </span>
                    <span class="ava-file__label">Прикрепить фото</span>
                  </div>
                </label>
              </div>
              <div class="col col--half">
                <div class="form-group">
                  <label for="name" class="label">ФИО <span v-if="dataChanged['name']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.name && touched }">
                    <input id="name" @change="saveChanges" v-model="form.name" class="form-control" required>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="phone" class="label">Телефон <span v-if="dataChanged['phone']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="phone" @change="saveChanges" v-model="form.phone" type="tel" class="form-control phone-mask">
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="email" class="label">E-mail <span v-if="dataChanged['email']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
                       :class="{ 'has-error': !form.name && touched }">
                    <input id="email" @change="saveChanges" v-model="form.email" class="form-control email-mask">
                  </div>
                </div>
              </div>

              <div class="w-100"></div>

              <div class="col col--half">
                <div class="form-group">
                  <label for="password" class="label">Пароль для входа <span v-if="dataChanged['password']" class="q-note q-note--saved">Сохранено</span></label>
                  <div class="form-field form-field--text form-field--2 form-field--m form-field--primary">
                    <input id="password" @change="saveChanges" v-model="form.password" :type="showPassword ? 'text' : 'password'">
                  </div>
                </div>
              </div>

              <div class="col col--half">
                <button @click="generateRandomPassword(16)" class="btn btn--transparent btn--icon" type="button">
                <span class="icon icon--m">
                  <svg class="svg" width="24" height="24">
                    <use :href="'/img/sprite-' + appVersion + '.svg#security-24'"/>
                  </svg>
                </span>Сгенерировать безопасный пароль</button>
              </div>

              <div v-if="setUserAsAdmin" class="col">
                <div class="form-group">
                  <label class="switch toggle toggle--m">
                    <input id="is_admin" @change="saveChanges" v-model="form.is_admin" type="checkbox" class="switch__input">
                    <span class="switch__cover toggle__cover"></span>

                    <span class="switch__label toggle__label">Администратор</span>
                  </label>
                </div>
              </div>

              <div class="col">
                <div v-if="error" class="login-box__message login-box__message_left">
                  <span class="form-error" v-html="error"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content__bottom content__bottom--bt">
        <div class="btns-wrap btns-wrap--sb">
          <button @click="cancel()" class="btn btn--2 btn--m btn--primary" type="reset">
            <span class="btn__label">Отмена</span>
          </button>
          <button v-if="user" @click="deleteUser" class="btn btn--4 btn--m btn--primary" type="button">
            <span class="btn__label">Заблокировать</span>
          </button>
          <button v-if="!user" :disabled="!isValid" @click="addUser" class="btn btn--1 btn--m btn--primary" type="button">
            <span class="btn__label">Добавить</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import packageInfo  from '../../../../package';
import SlimSelect from 'slim-select';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper
  },
  props: {
    roles: Array,
    user: Object,
    setUserAsAdmin: Boolean
  },
  data() {
    return {
      appVersion: packageInfo.version,
      error: null,
      showPassword: false,
      isValid: false,
      form: {
        name: null,
        phone: null,
        email: null,
        password: null,
        is_admin: null,
      },
      dataChanged: {},
      touched: null,
      init: true,
      photoUrl: null,
      photoToUpload: null,
      timer: null,
      imageToCrop: null,
    }
  },
  mounted() {
    if (this.user) {
      this.user.photo ? this.photoUrl = this.user.photo : null;
      for (var key in this.user) {
        if (this.user[key]) {
          this.form[key] = this.user[key];
        }
      }
    }
    this.$nextTick(function() {
      this.init = false;
    });
  },
  methods: {
    cancel() {
      location.href = '/settings/users';
    },
    cropSelectedImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        this.dataChanged = {};

        let formData = new FormData;
        canvas.toBlob(blob => {
          this.photoUrl = canvas.toDataURL();
          formData.append('photo', this.photoToUpload);
          formData.append('photo_cropped', blob);
          this.imageToCrop = null;
          this.saveUser(formData, 'photo');
        });
      }
    },
    uploadPhoto(event) {
      const { files } = event.target;
      if (files && files[0]) {
        this.photoToUpload = files[0];
        if (this.imageToCrop) {
          URL.revokeObjectURL(this.imageToCrop)
        }
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          this.imageToCrop = URL.createObjectURL(files[0]);
        }
      }
    },
    generateRandomPassword(length) {
      var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
      var pass = "";
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      this.form.password = pass;
      this.showPassword = true;

      if (this.user) {
        this.dataChanged = {};
        let formData = new FormData;
        formData.append('password', this.form.password);
        this.saveUser(formData, 'password');
      }
    },
    addUser() {
      let formData = new FormData;
      for (var key in this.form) {
        if (this.form[key] !== null && this.form[key] !== "") {
          formData.append(key, this.form[key]);
        }
      }
      if (this.photoToUpload) {
        formData.append('photo', this.photoToUpload);
      }

      axios.post('/settings/users', formData).then(() => {
        location.href = '/settings/users';
      }).catch((err) => {
        this.error = err.response.data.message;
      });
    },
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (!['name', 'email'].includes(key)) {
          continue;
        }
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    saveChanges(e) {
      if (!this.user) {
        this.validate();
      } else {
        if (this.init || !e) {
          return;
        }
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.dataChanged = {};
          let formData = new FormData;
          if (this.form[e.target.id]) {
            formData.append(e.target.id, this.form[e.target.id]);
          }
          this.saveUser(formData, e.target.id);
          this.touched = true;
        }, 200);
      }
    },
    saveUser(formData, field) {
      if (this.form.name) {
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        if (this.form.is_admin) {
          formData.append('is_admin', this.form.is_admin);
        }
        formData.append('_method', 'PUT');
        axios.post('/settings/users/' + this.user.id, formData).then(() => {
          this.dataChanged[field] = true;
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    },
    deleteUser() {
      if (confirm("Вы уверены, что хотите удалить пользователя " + this.form.name + "?")) {
        axios.delete('/settings/users/' + this.user.id).then(() => {
          location.href = '/settings/users';
        }).catch((err) => {
          this.error = err.response.data.message;
        });
      }
    }
  }
};
</script>